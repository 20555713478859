import { format, formatDate, parse, parseISO } from 'date-fns';

export function formatDateDateOnly(date: string | Date): string {
  return formatDate(date, 'dd-MMM-yyyy');
}

export function parseDateOnly(date: string): Date {
  return parse(date, 'dd-MMM-yyyy', new Date());
}

export function formatDateTimeUtcToLocal(date: string): string {
  console.log(date);
  const parsedDate = parseISO(date);
  return format(parsedDate, 'MMMM d @ H:MM a');
}
